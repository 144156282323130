import React, {useContext} from "react";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";

import AuthContext from "../../store/auth-context";
import TicketContext from "../../store/ticket-context";
import CreateUUID from "../../util/create-uuid";

const Header = () => {

    const authCtx = useContext(AuthContext)
    const ticketCtx = useContext(TicketContext);

    const logoutHandler = () => {
        authCtx.logout();
    }

    const getRandomHorses = (n) => {
        const numbers = ['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15'];
        const length = numbers.length;
        let arrHorseNumbers = [];
        while(arrHorseNumbers.length < n){
            let numHorse = numbers[Math.floor(Math.random()*length)].padStart(2, '0');
            if(arrHorseNumbers.indexOf(numHorse) === -1){
                arrHorseNumbers.push(numHorse);
            }
        }
        return arrHorseNumbers;
    }


    const getCurrentBet = () => {
        const cBetIndex = ticketCtx.bets.findIndex(
            (bet) => bet.id === ticketCtx.cBetId
        );
        return ticketCtx.bets[cBetIndex];
    }

    const onCreateSurpresinhaHandler = () => {
        const arrHorseNumbers = getRandomHorses(5);
        const currentBet = getCurrentBet();
        if(currentBet.horseNumbers.length === 0){
            currentBet.horseNumbers = arrHorseNumbers;
            ticketCtx.addBet(currentBet);
        } else {
            ticketCtx.addBet({
                id: CreateUUID(),
                horseNumbers: arrHorseNumbers,
                combined: false,
                price: 2,
                amount: 1
            });
        }
    }

    const onCancelTicketHandler = () => {
        ticketCtx.cancelTicket();
    }

    return (
        <Row className="bg-info p-2 justify-content-between align-items-center">
            <Col md="2" className="p-1">
                <Image src="img/favicon75x53.png" rounded />
            </Col>
            <Col className="text-center">
                <h3 className="font-weight-bold" style={{color: '#fff'}}>
                    JOCKEI CLUBE CEARENSE
                </h3>
            </Col>
            <Col md="5" className="text-right p-1">
                <Button className="btn-warning" onClick={onCreateSurpresinhaHandler}>Surpresa</Button>{' '}
                <Button className="alert-danger" onClick={onCancelTicketHandler}>Canc. Bilhete</Button>{' '}
                <Button className="btn-danger" onClick={logoutHandler} >Sair</Button>
            </Col>
        </Row>
    )
}

export default Header;