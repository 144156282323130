import React, {useContext, useEffect, useState} from "react";
import Jumbotron from "react-bootstrap/Jumbotron";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import TicketContext from "../../store/ticket-context";
import CreateUUID from "../../util/create-uuid";
import Combination from "./Combination/Combination";

const Keyboard = () => {

    const ticketCtx = useContext(TicketContext);

    //const arrCombinations = {'1/2': 2, '1/3': 3, '1/4': 4, 'Todos': 99}


    const getCurrentBet = () => {
        const cBetIndex = ticketCtx.bets.findIndex(
            (bet) => bet.id === ticketCtx.cBetId
        );
        return ticketCtx.bets[cBetIndex];
    }

    const addHorseHandler = (horseNumberObj) => {
        let bet = getCurrentBet();
        const horseNumber = horseNumberObj.target.value.padStart(2, '0');
        if(!isBetCompleted(bet)){
            if(horseAlreadyAdded(horseNumber) === false){
                bet.horseNumbers.push(horseNumber);
                ticketCtx.addBet(bet);
            } else {
                alert('Cavalo já escolhido para esta Pule.');
            }
        } else {
            createNewBet([horseNumber]);
        }
    }
    const addBetPriceHandler = (priceObj) => {
        let bet = getCurrentBet();
        bet.price = priceObj.target.value;
        ticketCtx.addBet(bet);
    }
    const addNewBetHandler = () => {
        // validation
        const scrollObj = { behavior: "smooth" }
        document.getElementById('dummy-div-to-scroll').scrollIntoView(scrollObj);

        createNewBet([]);
    }
    const createNewBet = (arrHorseNumbers = []) => {
        const newBetId = CreateUUID();
        return ticketCtx.addBet({
            id: newBetId,
            horseNumbers: arrHorseNumbers,
            combined: false,
            price: 2,
            amount: 1
        });
    }
    const isBetCompleted = (bet) => {
       if(!bet.combined && bet.horseNumbers.length === ticketCtx.max_numbers){
           return true;
       } else if(bet.combined && bet.horseNumbers.length === ticketCtx.max_combined){
           return true;
      }
       return false;
    }
    const horseAlreadyAdded = (horseNumber) => {
        const bet = getCurrentBet();
        if(bet.horseNumbers.indexOf(horseNumber) === -1){
            return false;
        }
        return true;
    }
    const setCombinedBetHandler = (event) => {
        let bet = getCurrentBet();
        const combined = event.target.value;
        if(bet.combined === combined){
            bet.combined = false;
            // Limite o número de cavalos em 5.
            bet.horseNumbers = bet.horseNumbers.slice(0, ticketCtx.max_numbers);
        } else {
            bet.combined = combined;
        }
        ticketCtx.addBet(bet);
    }

//    let combinations;
//    for(let key in arrCombinations){
 //       combinations += <Combination label={key} value={arrCombinations[key]} />
 //   }

    return(
        <Jumbotron className="alert-success h-100 p-1 mb-0">
            <Row className="p-1 text-center">
                <Col className="px-1 pl-3">
                    <Button className="w-100 px-1" onClick={setCombinedBetHandler} value={2} variant={+getCurrentBet().combined === 2 ? "info" :  "outline-info"} >1/2</Button>
                </Col>
                <Col className="px-1">
                    <Button className="w-100 px-1" onClick={setCombinedBetHandler} value={3} variant={+getCurrentBet().combined === 3 ? "info" :  "outline-info"} >1/3</Button>{' '}
                </Col>
                <Col className="px-1">
                    <Button className="w-100 px-1" onClick={setCombinedBetHandler} value={4} variant={+getCurrentBet().combined === 4 ? "info" :  "outline-info"} >1/4</Button>{' '}
                </Col>
                <Col className="px-1 pr-3">
                    <Button className="w-100 px-1" onClick={setCombinedBetHandler} value={99} variant={+getCurrentBet().combined === 99 ? "info" :  "outline-info"} >Todos</Button>
                </Col>
            </Row>
            <Row className="p-1 text-center">
                <Col className="px-1 pl-3">
                    <Button className="w-100 px-1"
                            onClick={addBetPriceHandler}
                            value={10}
                            variant={+getCurrentBet().price === 10 ? "info" : "outline-info"}
                    >R$10,00</Button>
                </Col>
                <Col className="px-1">
                    <Button className="w-100 px-1" onClick={addBetPriceHandler} value={5} variant={+getCurrentBet().price === 5 ? "info" : "outline-info"} >R$5,00</Button>{' '}
                </Col>
                <Col className="px-1">
                    <Button className="w-100 px-1" onClick={addBetPriceHandler} value={2} variant={+getCurrentBet().price === 2 ? "info" : "outline-info"} >R$2,00</Button>{' '}
                </Col>
                <Col className="px-1 pr-3">
                    <Button className="w-100 px-1" onClick={addNewBetHandler} variant="outline-info">Novo</Button>
                </Col>
            </Row>
            <Row className="p-1 text-center">
                <Col>
                    <Button onClick={addHorseHandler} value="1" disabled={getCurrentBet().horseNumbers.indexOf('01') !== -1} className="btn-success btn-lg">01</Button>{' '}
                    <Button onClick={addHorseHandler} value="2" disabled={getCurrentBet().horseNumbers.indexOf('02') !== -1} className="btn-success btn-lg">02</Button>{' '}
                    <Button onClick={addHorseHandler} value="3" disabled={getCurrentBet().horseNumbers.indexOf('03') !== -1} className="btn-success btn-lg">03</Button>
                </Col>
            </Row>
            <Row className="p-1 text-center">
                <Col>
                    <Button onClick={addHorseHandler} value="4" disabled={getCurrentBet().horseNumbers.indexOf('04') !== -1} className="btn-success btn-lg">04</Button>{' '}
                    <Button onClick={addHorseHandler} value="5" disabled={getCurrentBet().horseNumbers.indexOf('05') !== -1} className="btn-success btn-lg">05</Button>{' '}
                    <Button onClick={addHorseHandler} value="6" disabled={getCurrentBet().horseNumbers.indexOf('06') !== -1} className="btn-success btn-lg">06</Button>
                </Col>
            </Row>
            <Row className="p-1 text-center">
                <Col>
                    <Button onClick={addHorseHandler} value="7" disabled={getCurrentBet().horseNumbers.indexOf('07') !== -1} className="btn-success btn-lg">07</Button>{' '}
                    <Button onClick={addHorseHandler} value="8" disabled={getCurrentBet().horseNumbers.indexOf('08') !== -1} className="btn-success btn-lg">08</Button>{' '}
                    <Button onClick={addHorseHandler} value="9" disabled={getCurrentBet().horseNumbers.indexOf('09') !== -1} className="btn-success btn-lg">09</Button>
                </Col>
            </Row>
            <Row className="p-1 text-center">
                <Col>
                    <Button onClick={addHorseHandler} value="10" disabled={getCurrentBet().horseNumbers.indexOf('10') !== -1} className="btn-success btn-lg">10</Button>{' '}
                    <Button onClick={addHorseHandler} value="11" disabled={getCurrentBet().horseNumbers.indexOf('11') !== -1} className="btn-success btn-lg">11</Button>{' '}
                    <Button onClick={addHorseHandler} value="12" disabled={getCurrentBet().horseNumbers.indexOf('12') !== -1} className="btn-success btn-lg">12</Button>
                </Col>
            </Row>
            <Row className="p-1 text-center">
                <Col>
                    <Button onClick={addHorseHandler} value="13" disabled={getCurrentBet().horseNumbers.indexOf('13') !== -1} className="btn-success btn-lg">13</Button>{' '}
                    <Button onClick={addHorseHandler} value="14" disabled={getCurrentBet().horseNumbers.indexOf('14') !== -1} className="btn-success btn-lg">14</Button>{' '}
                    <Button onClick={addHorseHandler} value="15" disabled={getCurrentBet().horseNumbers.indexOf('15') !== -1} className="btn-success btn-lg">15</Button>
                </Col>
            </Row>
        </Jumbotron>
    )
}
export default Keyboard;