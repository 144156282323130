import React from 'react';
import CreateUUID from '../util/create-uuid';

const firstUUID = CreateUUID();

const TicketContext = React.createContext({
    max_numbers: 5,
    max_combined: 6,
    cBetId: firstUUID,
    cPrice: 2,
    bets: [{
        id: firstUUID,
        horseNumbers: [],
        price: 2,
        combined: false,
        amount: 1
    }],
    addBet: (bet) => {},
    removeBet: (betId) => {},
    removeBetNumber: (betId) => {},
    setCurrentBetId: (betId) => {},
    setCurrentPrice: (price) => {},
    cancelTicket: () => {}
});

export default TicketContext;