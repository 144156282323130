import React, { useContext } from "react";
import Jumbotron from "react-bootstrap/Jumbotron";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Bet from './Bet';

import TicketContext from "../../store/ticket-context";

const Ticket = () => {

    const ticketCtx = useContext(TicketContext);

    const style = {
        maxHeight: '400px',
        overflowY: 'scroll',
        scrollbarColor: 'transparent transparent'
        //overflow: 'hidden'

    }

    return (
        <Jumbotron className="alert-warning p-1 mb-0 h-100">
            <Row className="px-3" >
                <Col style={style}>
                    { ticketCtx.bets.map((bet, key) => {
                        return <Bet bet={bet} key={key} />
                    })}
                    <Row id="dummy-div-to-scroll" style={{height: '55px'}}>
                    </Row>
                </Col>
            </Row>
        </Jumbotron>
    )
}
export default Ticket;