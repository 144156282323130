import React, {useContext } from "react";
import { Redirect, Route, Switch } from 'react-router-dom';

import './App.css';

import LoginPage from './pages/LoginPage';
import PanelPage from './pages/PanelPage';
import AuthContext from "./store/auth-context";
import TicketProvider from "./store/TicketProvider";

function App () {
    const authCtx = useContext(AuthContext);

    return (
        <div>
            <Switch>
                { !authCtx.isLoggedIn && (
                    <Route path='/' exact={true}>
                        <LoginPage />
                    </Route>
                )}
                { authCtx.isLoggedIn && (
                        <Route path='/panel'>
                            <TicketProvider>
                                <PanelPage />
                            </TicketProvider>
                        </Route>
                )}
                <Route path="*">
                    <Redirect to="/" />
                </Route>
            </Switch>
        </div>
    );

}
export default App;
