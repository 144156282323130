import { useRef, useState, useContext } from 'react';
import { useHistory }  from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from "react-bootstrap/Spinner";

import AuthContext from "../store/auth-context";

function LoginPage() {

    const EXPIRATION_TIME_MINUTES = 5;
    const history = useHistory();
    const emailInputRef = useRef();
    const passwordInputRef = useRef();
    const authCtx = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);

    function submitHandler(event) {
        event.preventDefault();
        const email = emailInputRef.current.value;
        const password = passwordInputRef.current.value;
        const data = {
            username: email,
            password: password
        }

        const requestInfo = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                'Content-Type': 'application/json'
            }),
        };

        setIsLoading(true);
        //fetch('http://192.168.0.67:9000/api/v1/auth/login', requestInfo)
        fetch('https://api.minhadezena.com/api/user/login', requestInfo)
            .then(response => {
                setIsLoading(false);
                if(response.ok) {
                    return response.json()
                }
                throw new Error("Falha na Autenticação");
            })
            .then(token => {
                const expirationTime = new Date(new Date().getTime() + (1000 * 60 * EXPIRATION_TIME_MINUTES)); // 5min.
          //      authCtx.login(token['Authorization'], expirationTime.toISOString());
                authCtx.login(token.data.api_token, expirationTime.toISOString());
                history.replace('/panel');
                return;
            })
            .catch(e => {
                setIsLoading(false);
                //let errorMessage = 'Falha na autenticação!';
                //if(data && data.error && data.error.message){
                //  errorMessage = data.error.message;
                //}
                alert(e.message);
            });
    }

    return(
        <Container className="p-5">
            <Row className="">
                <Col className="mx-auto">
                    <Card className="mx-auto" style={{maxWidth:'350px', minWidth:'200px'}}>
                        <Card.Header className="text-center alert-info">
                            Área Restrita.
                        </Card.Header>
                        <Card.Body>
                            <Form onSubmit={submitHandler}>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Usuário</Form.Label>
                                    <Form.Control ref={emailInputRef} required type="text" placeholder="usuário" />
                                    <Form.Text className="text-muted">
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label>Senha</Form.Label>
                                    <Form.Control ref={passwordInputRef} required type="password" placeholder="senha" />
                                </Form.Group>
                                { !isLoading && <Button variant="info" className="" type="submit">Acessar</Button>}
                                { isLoading && <Button disabled variant="info" className="" type="submit">
                                    <Spinner as="span" animation="border" size="sm" /> Acessar</Button>}
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default LoginPage;