import React, { useReducer } from 'react';
import TicketContext from "./ticket-context";
import CreateUUID from '../util/create-uuid';
import CalcCombination from '../util/calc-combination';

const firstUUID = CreateUUID();

const defaultTicketState = {
    max_numbers: 5,
    max_combined: 6,
    cBetId: firstUUID,
    cPrice: 2,
    bets: [{
        id: firstUUID,
        horseNumbers: [],
        price: 2,
        combined: false,
        amount: 1
    }],
    addBet: (bet) => {},
    removeBet: (betId) => {},
    removeBetNumber: (betId) => {},
    setCurrentBetId: (betId) => {},
    setCurrentPrice: (price) => {},
    cancelTicket: () => {}
}

const ticketReducer = (state, action) => {
    if(action.type === 'ADD'){
        let newBet = action.bet;
        const existingBetIndex = state.bets.findIndex(
            (bet) => bet.id === newBet.id
        );
        const existingBet = state.bets[existingBetIndex];
        let updatedBets;
        if(existingBet){
            if(existingBet.combined){
                existingBet.amount = CalcCombination(existingBet, state);
            }
            updatedBets = [...state.bets];
            updatedBets[existingBetIndex] = existingBet;
        } else {
            if(newBet.combined){
                newBet.amount = CalcCombination(newBet, state);
            }
            updatedBets = state.bets.concat(newBet);
        }
        return {
            ...state,
            cBetId: action.bet.id,
            bets: updatedBets
        }
    } else if(action.type === 'REMOVE'){
        const existingBetIndex = state.bets.findIndex(
            (bet) => bet.id === action.betId
        );
        const existingBet = state.bets[existingBetIndex];
        let updatedBets;
        if(state.bets.length > 1){
            updatedBets = state.bets.filter((bet) => bet.id !== action.betId);
        } else {
            updatedBets = [{...existingBet, horseNumbers: []}];
        }
        return {
            ...state,
            bets: updatedBets
        }
    } else if(action.type === 'SET_CURRENT_BET'){
        let betId = action.betId;
        // Quando apagar uma bet, o setCurrentBetId é invocada com id undefined
        if(typeof action.betId === 'undefined'){
            betId = state.bets[0].id;
        } else {
            const existingBetIndex = state.bets.findIndex(
                (bet) => bet.id === betId
            );
            if(existingBetIndex === -1){
                betId = state.bets[0].id;
            }
        }
        return {
            ...state,
            cBetId: betId
        }
    } else if(action.type === 'SET_CURRENT_PRICE'){
        return {
            ...state,
            cPrice: action.price,
        }
    } else if(action.type === 'REMOVE_NUMBER'){
        const existingBetIndex = state.bets.findIndex(
            (bet) => bet.id === action.betId
        );
        const existingBet = state.bets[existingBetIndex];
        let updatedBets;
        if(existingBet){
            if(existingBet.horseNumbers.length > 0){
                existingBet.horseNumbers.pop();
                if(existingBet.combined){
                    existingBet.amount = CalcCombination(existingBet, state);
                }
                updatedBets = [...state.bets];
                updatedBets[existingBetIndex] = existingBet;
                return {
                    ...state,
                    bets: updatedBets
                }
            }
        }
        return {
            ...state
        }
    } else if(action.type === 'CANCEL_TICKET'){
        const newBetId = CreateUUID();
        let ticket = {
            cBetId: newBetId,
            cPrice: 2,
            bets: [{
                id: newBetId,
                horseNumbers: [],
                price: 2,
                combined: false,
                amount: 1
            }]
        }
        return {
            ...state,
            ...ticket
        }
    }
    return defaultTicketState;
};

const TicketProvider = (props) => {

    const [ticketState, dispachTicketAction] = useReducer(ticketReducer, defaultTicketState);
    const addBetHandler = (bet) => {
        dispachTicketAction({
            type: 'ADD',
            bet: bet
        });
    }
    const removeBetHandler = (betId) => {
        dispachTicketAction({
            type: 'REMOVE',
            betId: betId
        });
    }
    const removeBetNumberHandler = (betId) => {
        dispachTicketAction({
            type: 'REMOVE_NUMBER',
            betId: betId
        });
    }
    const setCurrentBetIdHandler = (betId) => {
        dispachTicketAction({
            type: 'SET_CURRENT_BET',
            betId: betId
        });
    }
    const setCurrentPriceHandler = (price) => {
        dispachTicketAction({
            type: 'SET_CURRENT_PRICE',
            price: price
        });
    }
    const cancelTicketHandler = () => {
        dispachTicketAction({
            type: 'CANCEL_TICKET'
        })
    }
    const ticketCtx = {
        max_numbers: ticketState.max_numbers,
        max_combined: ticketState.max_combined,
        cBetId: ticketState.cBetId,
        cPrice: ticketState.cPrice,
        bets: ticketState.bets,
        addBet: addBetHandler,
        removeBet: removeBetHandler,
        removeBetNumber: removeBetNumberHandler,
        setCurrentBetId: setCurrentBetIdHandler,
        setCurrentPrice: setCurrentPriceHandler,
        cancelTicket: cancelTicketHandler

    }

    return <TicketContext.Provider value={ticketCtx}>
        {props.children}
    </TicketContext.Provider>
}
export default TicketProvider;