
const factorial = (n) => {
    if(n === 0 || n === 1){
        return 1;
    } else {
        return n * factorial(n-1);
    }
}

const calcCombination = (bet, ticketCtx) => {
    let qtdHorses = bet.horseNumbers.length;
    let combined = bet.combined;
    let comb_limit = combined;
    // Se estiver selecionada a opcao de combinar TODOS, usa a quantidade total de calavos do ticket
    if(combined === '99'){
        if(qtdHorses < ticketCtx.max_numbers){
            combined = ticketCtx.max_numbers;
        } else {
            combined = qtdHorses;
        }
        comb_limit = ticketCtx.max_numbers;
    }
    return factorial(combined) / factorial(combined - comb_limit);
}

export default calcCombination;