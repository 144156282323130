import React, {useContext} from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import NumberFormat from 'react-number-format';

import TicketContext from "../../store/ticket-context";

function Bet(props){

    const ticketCtx = useContext(TicketContext);
    const MAX_NUMBERS = ticketCtx.max_numbers;
    const MAX_COMBINED = ticketCtx.max_combined;
    const bet = props.bet;
    const combined = props.bet.combined;
    let horseNumbers = [];
    let qtd_horses = MAX_NUMBERS;
    if(combined){
        qtd_horses = MAX_COMBINED;
    }
    for(let i=0; i < qtd_horses; i++){
        if(i < MAX_NUMBERS){
            horseNumbers[i] = '__';
        }
        if(typeof bet.horseNumbers[i] != "undefined"){
            horseNumbers[i] = bet.horseNumbers[i];
        }
    }
    if(combined){
        let horsesCombined = horseNumbers.slice(0, combined);
        horsesCombined = horsesCombined.join('x');
        horseNumbers = horseNumbers.slice(combined);
        horseNumbers = '('+horsesCombined+')-' + horseNumbers.join('-');
        if(combined >= MAX_NUMBERS){
            horseNumbers = horseNumbers.substr(0, horseNumbers.length-1);
        }
    } else {
        horseNumbers = horseNumbers.join('-');
    }

    const onSetCurrentBetHandler = () => {
        ticketCtx.setCurrentBetId(props.bet.id);
    }
    const onRemoveBetNumberHandler = () => {
        ticketCtx.removeBetNumber(props.bet.id);
    }
    const onRemoveBetHandler = () => {
        ticketCtx.removeBet(props.bet.id);
    }
    const getColor = () => {
        const style = {}
        if(props.bet.id === ticketCtx.cBetId){
            style.backgroundColor = '#d1ecf1';
            style.color = '#0c5460';
        } else if(props.bet.horseNumbers.length < 5){
            style.backgroundColor = '#f8d7da';
            style.color = '#721c24';
        } else {
            style.backgroundColor = '#fff3cd';
            style.color = '#856404';
        }
        return style;
    }

    const style = {
        borderWidth: '0 0 1px 0',
        borderStyle: 'dashed',
        borderColor: '#000',
        ...getColor(),
    }

    return (
        <Row className="px-0 alert-danger" onClick={onSetCurrentBetHandler} style={style} >
            <Col className="px-0">
                <Row>
                    <Col className="pr-0">
                        { horseNumbers }
                    </Col>
                </Row>
                <Row>
                    <Col className="align-bottom">
                        a <NumberFormat value={props.bet.price}
                                        displayType={'text'}
                                        decimalSeparator={','}
                                        thousandSeparator={'.'}
                                        decimalScale={2} prefix={'R$'}
                                        fixedDecimalScale={true}
                    /> Qtd:<NumberFormat value={props.bet.amount}
                                          displayType={'text'}
                                          decimalSeparator={','}
                                          thousandSeparator={'.'}
                                          decimalScale={0} prefix={''}
                                          fixedDecimalScale={true}
                    />
                    </Col>
                </Row>
            </Col>
            <Col className="text-right">
                <Row>
                    <Col>
                        <Row>
                            <Col className="text-right px-1">
                                <span><small><b>{ props.bet.combined ? 'combinada' : '' }</b></small></span></Col>
                            <Col className="text-right p-0 pr-2">
                                <Button
                                    className="btn-info btn-sm py-0"
                                    onClick={onRemoveBetNumberHandler} > &#xab;
                                </Button>{' '}
                                <Button
                                    className="btn-danger btn-sm py-0"
                                    onClick={onRemoveBetHandler} > X </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-right pr-2">
                        <small>
                            Subtotal: <NumberFormat value={props.bet.price * props.bet.amount}
                                                    displayType={'text'}
                                                    decimalSeparator={','}
                                                    thousandSeparator={'.'}
                                                    decimalScale={2} prefix={'R$'}
                                                    fixedDecimalScale={true}
                        />
                        </small>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
export default Bet;