import React, {useContext} from 'react';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";

import TicketContext from "../../store/ticket-context";
import NumberFormat from "react-number-format";

const Footer = () => {

    const ticketCtx = useContext(TicketContext);

    const total = +ticketCtx.bets.reduce((price, bet) => {
        return price + (+bet.price * +bet.amount);
    }, 0);

    return (
        <Row className="bg-info p-2 text-center justify-content-between align-items-center">
            <Col sm="12" md="6" className="p-1">
                <Row>
                    <Col sm="6" md="4" className="p-1 px-3 pr-lg-1">
                        <Button className="w-100 btn-warning">Resultado</Button>
                    </Col>
                    <Col sm="6" md="4" className="p-1 px-3 pl-lg-1 pr-lg-1">
                        <Button className="w-100 btn-dark">Agendar</Button>{' '}
                    </Col>
                    <Col sm="12" md="4" className="p-1 px-3 pl-lg-1">
                        <Button className="w-100 btn-success">Imprimir</Button>{' '}
                    </Col>
                </Row>
            </Col>
            <Col sm="12" md="6">
                <h3 style={{color: '#fff'}}>
                    Total <NumberFormat value={total}
                                        displayType={'text'}
                                        decimalSeparator={','}
                                        thousandSeparator={'.'}
                                        decimalScale={2} prefix={'R$'}
                                        fixedDecimalScale={true}
                        />
                </h3>
            </Col>
        </Row>
    );
}
export default Footer;