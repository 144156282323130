import React from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import Keyboard from '../components/Keyboard/Keyboard';
import Ticket from "../components/Ticket/Ticket";

const PanelPage = () => {

    const onKeyDownHandler = (event) => {



    }

    return(
        <Container fluid className="container p-4" onKeyDown={onKeyDownHandler} >
            <Jumbotron className="p-0">
                <Header />
                <Row className="bg-info px-1 py-0">
                    <Col md="6" sm="12" className="p-2">
                        <Keyboard />
                    </Col>
                    <Col md="6" sm="12" className="p-2">
                        <Ticket />
                    </Col>
                </Row>
                <Footer />
            </Jumbotron>
        </Container>
    )
}
export default PanelPage;
